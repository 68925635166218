import type { FC } from 'react'
import type { StackProps } from '@chakra-ui/react'
import type { FormFieldType, FormUpdatePayload, OnFormSubmit } from 'react-formatge/dist/esm/types'
import { formValidationRgx } from 'react-formatge'
import { overrideText } from 'ui/lib/overrides'
import { useOverridesContext } from 'ui/lib/overrides/hooks'
import type { CreateCustomerDTO } from 'ecosystem'
import { FormWrapperCustom } from '../components'

export type SignupFormType = Omit<CreateCustomerDTO, 'type'>

interface SignupFormProps extends StackProps {
  onFormSubmit?: OnFormSubmit<Partial<SignupFormType>>
  error?: string
  onUpdate?: (payload: FormUpdatePayload<SignupFormType>) => void | Promise<void>
}

export interface SignupFormOverrides {
  signupFormEmail?: string
  signupFormFirstName?: string
  signupFormLastName?: string
  signupFormBtnLabel?: string
}

const SignupForm: FC<SignupFormProps> = ({ onUpdate, error, onFormSubmit, ...props }) => {
  const overrides = useOverridesContext<keyof SignupFormOverrides>()

  const inputFields: FormFieldType<SignupFormType>[] = [
    {
      name: 'email',
      componentType: 'input',
      label: overrideText('E-postadress', overrides?.signupFormEmail),
      placeholder: overrideText('E-postadress', overrides?.signupFormEmail),
      initialValue: '',
      validation: {
        required: true,
        validator: {
          regEx: formValidationRgx.email,
          error: 'Invalid email format.'
        }
      }
    },
    {
      name: 'firstName',
      componentType: 'input',
      label: overrideText('Förnamn', overrides?.signupFormFirstName),
      placeholder: overrideText('Förnamn', overrides?.signupFormFirstName),
      initialValue: '',
      validation: {
        required: true
      }
    },
    {
      name: 'lastName',
      componentType: 'input',
      label: overrideText('Efternamn', overrides?.signupFormFirstName),
      placeholder: overrideText('Efternamn', overrides?.signupFormFirstName),
      initialValue: '',
      validation: {
        required: true
      }
    }
  ]

  const buttonProps = {
    children: overrideText('Registrera', overrides?.signupFormBtnLabel)
  }

  const handleOnFormSubmit = async (updatedData: Partial<SignupFormType>) => {
    if (!onFormSubmit) return
    await onFormSubmit(updatedData)
  }

  return (
    <FormWrapperCustom<SignupFormType>
      onSubmitCb={handleOnFormSubmit}
      {...{ inputFields, buttonProps, error, onUpdate }}
      {...props}
    />
  )
}

export default SignupForm
